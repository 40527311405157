import {
  generateKey, crypto, enums, util,
} from 'openpgp';

async function generateKeys(name) {
  if (!name) throw new Error('name value is required');

  const passphrase = util
    .Uint8Array_to_hex(
      await crypto.hash.digest(enums.hash.sha256, await crypto.random.getRandomBytes(32)),
    );

  const keyPair = await generateKey({
    numBits: 2048,
    passphrase,
    userIds: [{ name, email: '' }],
  });

  return {
    publicKey: keyPair.publicKeyArmored,
    privateKey: keyPair.privateKeyArmored,
    passphrase,
  };
}

export default generateKeys;
