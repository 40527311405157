<template>
  <section class="main-view">
    <loader v-if="isLoading"></loader>
    <div class="columns">
      <div class="column is-four-fifths">
        <div class="column">

          <div class="buttons">
          <b-button class="button is-success" @click="generatePgpKeys">
            Generate Keys
          </b-button>
          </div>
          <b-notification
            v-if="passphrase"
            type="is-info"
            has-icon
            aria-close-label="Close notification">
             The generated passphrase, public key and private
             keys below will not be retrievable once you
             leave this page or re-generate the information.
             Make sure to use the download buttons and upload
             the public key for use with KxPayments.</b-notification>
        </div>
        <b-field label="Passphrase">
          <b-input readonly :disabled="!this.passphrase" v-model="this.passphrase" type="text">
          </b-input>
        </b-field>
        <div class="column">
          <b-button :disabled="!this.passphrase" class="button is-primary" outlined
           @click="downloadPassphrase">
            Download Passphrase
          </b-button>
        </div>
        <b-field label="Public Key">
          <b-input readonly :disabled="!this.publicKey" v-model="this.publicKey" type="textarea">
          </b-input>
        </b-field>
        <div class="column">
          <div class="buttons">
            <b-button readonly :disabled="!this.publicKey" class="button is-primary" outlined
            @click="downloadPublicKey">
              Download Public Key
            </b-button>
            <b-button readonly :disabled="!this.publicKey" class="button is-primary"
            @click="uploadPublicKeyConfirm">
              Use this Public Key
            </b-button>
          </div>
        </div>
        <b-field label="Private Key">
          <b-input readonly :disabled="!this.privateKey" v-model="this.privateKey" type="textarea">
          </b-input>
        </b-field>
        <div class="column">
          <b-button :disabled="!this.privateKey" class="button is-primary" outlined
             @click="downloadPrivateKey">
              Download Private Key
          </b-button>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import { saveAs } from 'file-saver';
import generateKeys from './generateKeys';

export default {
  data() {
    return {
      isLoading: false,
      publicKey: null,
      privateKey: null,
      passphrase: null,
    };
  },
  computed: {},
  mounted() {
    this.dataService = this.$dataService(this.$route.params.tenant);
  },
  methods: {
    async generatePgpKeys() {
      this.isLoading = true;
      const { publicKey, privateKey, passphrase } = await generateKeys(
        this.$route.params.tenant,
      );
      this.publicKey = publicKey;
      this.privateKey = privateKey;
      this.passphrase = passphrase;
      this.isLoading = false;
    },
    downloadPublicKey() {
      const blob = new Blob([this.publicKey], {
        type: 'text/plain;charset=utf-8',
      });
      saveAs(blob, 'publicKey-pub.asc');
    },
    downloadPrivateKey() {
      const blob = new Blob([this.privateKey], {
        type: 'text/plain;charset=utf-8',
      });
      saveAs(blob, 'privateKey-sec.asc');
    },
    downloadPassphrase() {
      const blob = new Blob([this.passphrase], {
        type: 'text/plain;charset=utf-8',
      });
      saveAs(blob, 'passphrase-sec.txt');
    },
    uploadPublicKeyConfirm() {
      this.$buefy.dialog.confirm({
        title: 'Uploading Public Key',
        message: 'This action will override any existing public key you have previously uploaded - are you sure?',
        confirmText: 'Upload Public Key',
        icon: 'mdi mdi-alert-circle',
        hasIcon: true,
        onConfirm: () => this.uploadPublicKey(),
      });
    },
    uploadPublicKey() {
      this.isLoading = true;
      const formData = new FormData();
      const blob = new Blob([this.publicKey], {
        type: 'text/plain;charset=utf-8',
      });
      formData.append('public-key', blob);
      this.dataService.publicKeys
        .uploadKeys(formData)
        .then(({ data }) => {
          this.isLoading = false;
          this.$buefy.toast.open(`Key uploaded! Fingerprint : ${data.fingerprint}`);
        })
        .catch((error) => {
          this.isLoading = false;
          this.$buefy.toast.open(`Failed to upload key. ${error.response.data.message}`);
          throw error;
        });
    },
  },
};
</script>
